<template>
  <!-- 基础信息 -->
  <div class="base-info-wrapper">
    <!-- 商品信息 -->
    <el-descriptions style="margin-bottom:20px;" direction="vertical" :column="10" border>
      <el-descriptions-item label="订单号" :span="2" label-class-name="my-label">{{ $route.params.id }}</el-descriptions-item>
      <template v-if="detail.orderGoods">
        <el-descriptions-item label="商品描述" :span="3" label-class-name="my-label">
          <div class="handle">
            <span>{{ detail.orderGoods.skuTitle }}</span>
            <span>
              <el-button
                v-show="detail.status < 3 && isShowBtn(AUTH_BTN.order_list_update)"
                size="mini"
                type="primary"
                @click="$router.push({ name: 'OrderUpdateGoods', params: { id: $route.params.id }, query: { memberId: $route.query.memberId } })"
              >修改</el-button>
            </span>
          </div>
        </el-descriptions-item>
      </template>
      <template v-if="detail.orderGoods">
        <el-descriptions-item label="成色" :span="2" label-class-name="my-label">
          <el-tag :type="detail.orderGoods.goodsPurityName == '全新' ? 'primary' : 'warning'" size="mini">
            {{ detail.orderGoods.goodsPurityName }}
          </el-tag>
        </el-descriptions-item>
      </template>
      <el-descriptions-item label="订单状态" :span="1" label-class-name="my-label">{{ detail.statusName }}</el-descriptions-item>
      <el-descriptions-item label="下单时间" :span="2" label-class-name="my-label">{{ detail.createTime }}</el-descriptions-item>
      <!-- 第二排 -->
      <el-descriptions-item label="费率" :span="1" label-class-name="my-label"> {{ detail.priceRate }} % </el-descriptions-item>
      <el-descriptions-item label="零售价" :span="1" label-class-name="my-label">
        <div class="handle">
          <span> {{ detail.salePrice }}</span>
          <span>
            <el-button
                v-show="isShowBtn(AUTH_BTN.order_list_detail_update_sale_price) && detail.goodsCombo == 'payAtOnce' && detail.status == 2"
                size="mini"
                type="primary"
                @click="updatePrice"
            >修改</el-button>
          </span>
        </div>
      </el-descriptions-item>
      <el-descriptions-item label="首付租金" :span="2" label-class-name="my-label">
        {{ detail.prepayRentPrice }}
        <el-tag type="primary" v-if="detail.prepayPayMode == 'member:offline'" size="mini">线下支付 </el-tag>
        <el-tag type="success" v-if="detail.prepayPayMode != '' && detail.prepayPayMode != 'member:offline'" size="mini">
          线上支付
        </el-tag>
      </el-descriptions-item>
      <el-descriptions-item label="租期" :span="1" label-class-name="my-label">{{ detail.billPeriodNum }} 期</el-descriptions-item>
      <el-descriptions-item label="总租金" :span="1" label-class-name="my-label">{{ detail.rentPrice }}</el-descriptions-item>
      <el-descriptions-item label="保险金" label-class-name="my-label">{{ detail.insurancePrice }}</el-descriptions-item>
      <el-descriptions-item label="溢价金" :span="1" label-class-name="my-label" content-class-name="my-content">
        <div class="handle">
          <span>{{ detail.overflowPrice }}</span>
        </div>
      </el-descriptions-item>
      <el-descriptions-item label="买断金" :span="1" label-class-name="my-label">{{ detail.buyoutPrice }}</el-descriptions-item>
    </el-descriptions>
    <!-- 零售价 -->
    <el-dialog title="修改零售价" :visible.sync="salePriceDialog" width="800px">
      <el-form ref="periodForm" :model="salePriceData" label-width="80px">
        <el-form-item label="新零售价">
          <el-input-number
              style="width:200px"
              v-model="salePriceData.salePrice"
              size="small"
              :precision="2"
              :min="0"
              :step="1"
              step-strictly
          ></el-input-number>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="salePriceDialog = false" size="small">取 消</el-button>
        <el-button type="primary" @click="savePrice" size="small">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { authBtnMixin } from '@/mixins/authBtnMixin'
import { updatePriceAPI } from './api'

export default {
  name: 'BaseInfo',
  mixins: [authBtnMixin],
  props: {
    detail: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      salePriceDialog: false,
      salePriceData: {
        salePrice: '',
        orderNo: ''
      },
    }
  },
  computed: {
    is_show() {
      const { status } = this.detail
      const status_arr = [5, 6, 7, 91, 92, 93]
      const res = status_arr.indexOf(status)
      return res == -1 ? false : true
    }
 
  },
  mounted() {},

  methods: {
    // 打开修改零售价
    updatePrice() {
      this.salePriceData.orderNo = this.$route.params.id
      this.salePriceData.salePrice = this.detail.salePrice
      this.salePriceDialog = true
    },
    // 保存零售价
    savePrice() {
      updatePriceAPI(this.salePriceData).then(() => {
        this.$emit('updateDetail')
        this.$message.success('修改成功')
        this.salePriceDialog = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.base-info-wrapper /deep/ {
  width: 100%;
  .el-descriptions {
    .my-label {
      color: #1a1e25;
      background: #fafafa;
    }
    .handle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > span {
        &:first-child {
          margin-right: 20px;
          flex: 1;
        }
      }
    }
  }
}
</style>
