<template>
  <div class="wrapper">
    <!-- <div style="white-space: pre-wrap;" v-html="demo"></div> -->
    <!-- 商品信息 -->
    <BaseInfo :detail="detail" />
    <div class="title">身份证照片</div>
    <IdCard :member="detail.member" :detail="detail" />
    <div class="title">收货信息</div>
    <Address :flag.sync="flag" :orderAddress="detail.orderAddress" :status="detail.status" @updateDetail="getDetail" />
    <div class="title">发货信息</div>
    <SendInfo :orderDelivery="detail.orderDelivery || {}" :detail="detail" />
    <!-- 工作单位 -->
    <Job :flag.sync="flag" :jobInfo="detail.memberProfile || {}" :member="detail.member" @updateDetail="getDetail" />
    <Contact :member="detail.member" />
    <div class="title">客户归属</div>
    <SelectCustomer :status="detail.status" :flag.sync="flag" :kfUserId="detail.kfUserId" @updateDetail="getDetail" />
    <div class="title">用户评分</div>
    <Rate :flag.sync="flag" :memberGradeLog="detail.memberGradeLog" @updateDetail="getDetail"></Rate>
    <el-divider></el-divider>
    <div style="text-align:right">
      <el-button type="" size="small" @click="closePage">关 闭</el-button>
    </div>
    <div style="height:60px;"></div>
  </div>
</template>

<script>
import { getDetailAPI } from './api'
import BaseInfo from './modules/baseInfo.vue'
import Address from './modules/address.vue'
import SendInfo from './modules/send-info.vue'
import Contact from './modules/contact.vue'
import SelectCustomer from './modules/select-customer.vue'
import Rate from './modules/rate.vue'
import IdCard from './modules/id-card.vue'
import Job from './modules/job.vue'
export default {
  name: 'DetailBase',
  components: { BaseInfo, Address, SendInfo, Contact, SelectCustomer, Rate, IdCard, Job },
  data() {
    return {
      detail: {},
      flag: true
    }
  },

  mounted() {
    // this.getDetail()
  },
  activated() {
    this.getDetail()
  },
  methods: {
    async getDetail() {
      this.detail = await getDetailAPI(this.$route.params.id)
    },
    closePage() {
      this.emitAwindow()
      window.close()
    },
    emitAwindow() {
      //window.opener 获取父页面的window元素
      //判断A窗口有没有window.opener和getBpageList是不是个方法
      if (window.opener && window.opener.getBpageList) {
        window.opener.getBpageList()
      } else {
        //window.opener.frames[0] 获取到的window对象
        window.opener.frames[0].getBpageList()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .title {
    font-weight: 600;
    margin: 20px 0;
    &:first-child {
      margin-top: 0;
    }
  }
}
</style>
