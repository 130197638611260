<template>
  <!-- 发货信息 -->
  <div class="wrapper">
    <el-descriptions style="margin-bottom:20px;" direction="vertical" :column="6" border>
      <el-descriptions-item label="采购价" label-class-name="my-label"> {{ detail.costPrice }}</el-descriptions-item>
      <el-descriptions-item label="手机串号" label-class-name="my-label">{{ orderDelivery.serialNo }}</el-descriptions-item>
      <el-descriptions-item label="快递公司" label-class-name="my-label">{{ orderDelivery.deliveryCompanyName }}</el-descriptions-item>
      <el-descriptions-item label="快递单号" label-class-name="my-label">{{ orderDelivery.deliveryNo || '暂无数据' }}</el-descriptions-item>
      <el-descriptions-item label="发货时间" label-class-name="my-label"> {{ detail.deliveryTime }}</el-descriptions-item>
      <el-descriptions-item label="签收时间" label-class-name="my-label"> {{ detail.receiveTime }}</el-descriptions-item>
      <el-descriptions-item label="发货图片" :span="1" label-class-name="my-label">
        <el-image
          v-if="orderDelivery.deliveryImageUrl"
          style="width: 60px; height:60px;"
          :src="orderDelivery.deliveryImageUrl"
          :preview-src-list="[orderDelivery.deliveryImageUrl]"
        >
        </el-image>
        <span v-else>暂无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="签收证明" :span="3" label-class-name="my-label">
        <span v-for="(item, i) in orderDelivery.receiveImageUrlList" :key="i">
          <el-image style="width:60px; height: 60px;margin-right:10px" v-if="item" :key="i" :src="item" :preview-src-list="[item]"> </el-image>
        </span>
      </el-descriptions-item>
      <el-descriptions-item v-if="is_show" label="备注" :span="2" label-class-name="my-label">
        <span>{{ orderDelivery.remark || '暂无数据' }}</span>
      </el-descriptions-item>
      <el-descriptions-item v-if="orderDelivery.lastTraceNode" label="物流信息" :span="1" label-class-name="my-label">
        <div class="fahuo-detail">
          <span> {{ orderDelivery.lastTraceNode.statusName }}</span>
          <span style="padding-right:10px">
            <el-button
                v-checkbtn="isShowBtn(AUTH_BTN.order_list_delivery_detail)"
                type="primary"
                size="mini"
                @click="showDeliveryDetail"
            >详情</el-button>
          </span>
        </div>
      </el-descriptions-item>
    </el-descriptions>
    <!-- 物流信息 -->
    <el-drawer
        class="delivery-box"
        title="物流详情"
        :show-close="true"
        :withHeader="true"
        size="60%"
        :visible.sync="drawer"
        direction="rtl"
    >
      <div class="delivery-detail">
        <admin-title title="收件人信息"></admin-title>
        <el-descriptions direction="vertical" :column="5" border>
          <el-descriptions-item label="收件人">{{ deliveryDetail.contactName }}</el-descriptions-item>
          <el-descriptions-item label="联系方式">{{ deliveryDetail.contactMobile }}</el-descriptions-item>
          <el-descriptions-item label="快递公司" :span="1">{{ deliveryDetail.deliveryCompanyName }}</el-descriptions-item>
          <el-descriptions-item label="快递单号">{{ deliveryDetail.deliveryNo }}</el-descriptions-item>
          <el-descriptions-item label="收货地址">{{ deliveryDetail.contactAddress }}</el-descriptions-item>
        </el-descriptions>
        <admin-title title="物流信息"></admin-title>
        <div class="delivery-list">
          <div class="item" v-for="(item, i) in deliveryDetail.nodeList" :key="i">
            <div :class="['circle', i == 0 ? 'green' : '']">
              <i class="el-icon-check"></i>
            </div>
            <div :class="['dsc', i == 0 ? 'first' : '']">
              <div class="status">{{ item.statusName }}</div>
              <div class="content">{{ item.content }}</div>
              <div class="time">{{ item.time }}</div>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { getDeliveryDetailAPI } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin'

export default {
  name: 'SendInfo',
  mixins: [authBtnMixin],
  props: {
    orderDelivery: {
      type: Object,
      default: () => {}
    },
    detail: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      drawer: false,
      deliveryDetail: {}
    }
  },
  computed: {
    is_show() {
      const { status } = this.detail
      const status_arr = [5, 6, 7, 91, 92, 93]
      const res = status_arr.indexOf(status)
      return res == -1 ? false : true
    }
  },
  mounted() {},

  methods: {
    // 编辑联系人
    editUser() {
      this.dialogVisibleUser = true
    },
    // 保存工作信息
    saveJob() {
      this.$confirm('工作单位未填写，您确定要提交吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    },
    async showDeliveryDetail() {
      this.deliveryDetail = await getDeliveryDetailAPI(this.$route.params.id)
      this.drawer = true
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .el-descriptions {
    .my-label {
      color: #1a1e25;
      background: #fafafa;
    }
    .my-content {
      .handle {
        display: flex;
        justify-content: space-between;
        & > span {
          &:first-child {
            margin-right: 20px;
            flex: 1;
          }
        }
      }
    }
  }
  .contact-remark {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    .item {
      background: #fafafa;
      flex: 1;
      border: 1px solid #ebeef5;
      padding: 5px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:first-child {
        margin-right: 20px;
      }
    }
  }
  .contact-remark-table {
    .header {
      margin-bottom: 10px;
      background: #fafafa;
      padding: 5px 10px;
      .handle-btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left {
          display: flex;
          align-items: center;
          flex: 1;
          margin-right: 10px;
          .el-input {
            margin-right: 10px;
            width: 50%;
          }
        }
      }
    }
    .tp-table {
      .el-table__fixed-right {
        height: 100% !important;
      }
    }
  }
  .fahuo-detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .delivery-box {
    .el-drawer__header {
      padding-bottom: 0;
      margin-bottom: 0;
      & > span {
        font-weight: 600;
        font-size: 17px;
      }
    }
    .delivery-detail {
      padding: 20px;

      .delivery-list {
        padding: 20px;
        max-height: calc(100vh - 330px);
        overflow-y: scroll;
        &::-webkit-scrollbar {
          width: 3px;
          background-color: #ccc;
        }
        .item {
          position: relative;
          min-height: 100px;
          border-left: 4px solid #ccc;

          padding: 20px;
          padding-top: 0;
          &:last-child {
            border-left: none;
          }
          .circle {
            position: absolute;
            top: 0;
            left: 0;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            background: #666;
            transform: translate(-53%, -10%);
            display: flex;
            justify-content: center;
            align-items: center;
            i {
              color: #fff;
              font-weight: 600;
            }
          }
          .green {
            background: #66b1ff;
          }
          .dsc {
            color: #333;
            // .status {
            // }
            .content {
              margin: 10px 0;
            }
            .time {
              color: #666666;
            }
          }
          .first {
            color: #66b1ff;
            .time {
              color: #66b1ff;
            }
          }
        }
      }
    }
  }
}
</style>
